import { createGlobalStyle } from 'styled-components';
import { withPrefix } from 'gatsby';

export default createGlobalStyle`

  @font-face {
    font-family: "Malee Serif";
    src: url(${withPrefix('/fonts/Malee-Serif.eot')});
    src: url(${withPrefix(
      '/fonts/Malee-Serif?#iefix'
    )}) format('embedded-opentype'),
         url(${withPrefix('/fonts/Malee-Serif.woff2')}) format('woff2'),
         url(${withPrefix('/fonts/Malee-Serif.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Malee Sans";
    src: url(${withPrefix('/fonts/Malee-Sans.eot')});
    src: url(${withPrefix(
      '/fonts/Malee-Sans?#iefix'
    )}) format('embedded-opentype'),
         url(${withPrefix('/fonts/Malee-Sans.woff2')}) format('woff2'),
         url(${withPrefix('/fonts/Malee-Sans.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Anouk";
    src:    url(${withPrefix('/fonts/Anouk-Regular_web.eot')});
    src:    url(${withPrefix(
      '/fonts/Anouk-Regular_web.eot?#iefix'
    )}) format('embedded-opentype'),             
    url(${withPrefix('/fonts/Anouk-Regular_web.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Anouk-Regular_web.woff')}) format('woff'),
    url(${withPrefix(
      '/fonts/Anouk-Regular_web.svg#svgFontName'
    )}) format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Bely Display";
    src: url(${withPrefix('/fonts/BelyDisplay-Regular.eot')});
    src: url(${withPrefix(
      '/fonts/BelyDisplay-Regular.eot#iefix'
    )}) format('embedded-opentype'),
         url(${withPrefix('/fonts/BelyDisplay-Regular.woff2')}) format('woff2'),
         url(${withPrefix('/fonts/BelyDisplay-Regular.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Pilot";
    src: url(${withPrefix('/fonts/Pilot-Black.eot')});
    src: url(${withPrefix(
      '/fonts/Pilot-Black.eot#iefix'
    )}) format('embedded-opentype'),
        url(${withPrefix('/fonts/Pilot-Black.woff2')}) format('woff2'),
        url(${withPrefix('/fonts/Pilot-Black.woff')}) format('woff'),
        url(${withPrefix('/fonts/Pilot-Black.otf')}) format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "Quinn Display";
    src: url(${withPrefix('/fonts/QuinnDisplay-Thin.eot')});
    src: url(${withPrefix(
      '/fonts/QuinnDisplay-Thin.eot#iefix'
    )}) format('embedded-opentype'),
         url(${withPrefix('/fonts/QuinnDisplay-Thin.woff2')}) format('woff2'),
         url(${withPrefix('/fonts/QuinnDisplay-Thin.woff')}) format('woff'),
         url(${withPrefix('/fonts/QuinnDisplay-Thin.otf')}) format('otf');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "Tiempos Fine";
    src: url(${withPrefix('/fonts/TiemposFineWeb-MediumItalic.eot')});
    src: url(${withPrefix(
      '/fonts/TiemposFineWeb-MediumItalic.eot#iefix'
    )}) format('embedded-opentype'),
         url(${withPrefix(
           '/fonts/TiemposFineWeb-MediumItalic.woff2'
         )}) format('woff2'),
         url(${withPrefix(
           '/fonts/TiemposFineWeb-MediumItalic.woff'
         )}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "Trola";
    src: url(${withPrefix(
      '/fonts/tipografies-trola-light.otf'
    )}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Parfumerie Script";
    src: url(${withPrefix('/fonts/Parfumerie.eot')});
    src: url(${withPrefix(
      '/fonts/Parfumerie.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix('/fonts/Parfumerie.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Parfumerie.woff')}) format('woff'),
    url(${withPrefix('/fonts/Parfumerie.ttf')}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Nomada Didone";
    src: url(${withPrefix('/fonts/Nomada.eot')});
    src: url(${withPrefix(
      'fonts/Nomada.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix('/fonts/Nomada.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Nomada.woff')}) format('woff'),
    url(${withPrefix('/fonts/Nomada.ttf')}) format('truetype');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: "William Subhead";
    src: url(${withPrefix(
      '/fonts/WilliamSubheadStd-RegularItalic.otf'
    )}) format('opentype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Messer";
     src:
    url(${withPrefix('/fonts/MesserV1.4-Condensed.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/MesserV1.4-Condensed.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Dunkel Sans";
     src:
    url(${withPrefix('/fonts/Dunkelsansv0.5-Heavy.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Dunkelsansv0.5-Heavy.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "CoFo_Chimera";
     src:
    url(${withPrefix('/fonts/CoFo_Chimera-Bold_Italic.woff')}) format('woff'),
    url(${withPrefix(
      '/fonts/CoFo_Chimera-Bold_Italic.ttf'
    )}) format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "NariVariable-Soft";
     src:
     url(${withPrefix('/fonts/NariVariable-Soft.woff2')}) format('woff2'),
     url(${withPrefix('/fonts/NariVariable-Soft.woff')}) format('woff'),
     url(${withPrefix('/fonts/NariVariable-Soft.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Checkmate-Roman";
    src:
      url(${withPrefix('/fonts/Checkmate-Roman.woff')}) format('woff'),
      url(${withPrefix('/fonts/Checkmate-Roman.ttf')}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Ilai";
    src:
         url(${withPrefix(
           '/fonts/IlaiMaleeWEB-BottomMedium.woff2'
         )}) format('woff2'),
         url(${withPrefix(
           '/fonts/IlaiMaleeWEB-BottomMedium.woff'
         )}) format('woff'),
         url(${withPrefix(
           '/fonts/IlaiMaleeWEB-BottomMedium.eot'
         )}) format("embedded-opentype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "MangoGujarati-Regular";
     src:
     url(${withPrefix('/fonts/MangoGujarati-Regular.woff')}) format('woff'),
     url(${withPrefix('/fonts/MangoGujarati-Regular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Balcony";
     src:
     url(${withPrefix('/fonts/Balcony-Light.woff')}) format('woff'),
     url(${withPrefix('/fonts/Balcony-Light.otf')}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "CeraphTrial";
     src:
     url(${withPrefix('/fonts/CeraphTrial.woff')}) format('woff');    
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Tiga-TextRegular";
    src: url(${withPrefix('/fonts/Tiga-TextRegular.eot')});
    src: url(${withPrefix(
      'fonts/Tiga-TextRegular.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix('/fonts/Tiga-TextRegular.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Tiga-TextRegular.woff')}) format('woff'),
    url(${withPrefix('/fonts/Tiga-TextRegular.ttf')}) format('truetype'),
    url(${withPrefix('/fonts/Tiga-TextRegular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "TirhalBold";
    src: url(${withPrefix('/fonts/TirhalBold.woff')}) format('woff'),
    url(${withPrefix('/fonts/TirhalBold.otf')}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }


  @font-face {
    font-family: "Arvana";
    src: url(${withPrefix('/fonts/Arvanav0.3-Regular.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Arvanav0.3-Regular.woff')}) format('woff'),
    url(${withPrefix('/fonts/Arvanav0.3-Regular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Arvana";
    src: url(${withPrefix('/fonts/Arvanav0.3-Black.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Arvanav0.3-Black.woff')}) format('woff'),
    url(${withPrefix('/fonts/Arvanav0.3-Black.otf')}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "FauneDisplay";
    src: url(${withPrefix('/fonts/Faune-Display_Bold_Italic.eot')});
    src: url(${withPrefix(
      'fonts/Faune-Display_Bold_Italic.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix(
      '/fonts/Faune-Display_Bold_Italic.woff2'
    )}) format('woff2'),
    url(${withPrefix('/fonts/Faune-Display_Bold_Italic.woff')}) format('woff'),
    url(${withPrefix(
      '/fonts/Faune-Display_Bold_Italic.ttf'
    )}) format('truetype'),
    url(${withPrefix(
      '/fonts/Faune-Display_Bold_Italic.otf'
    )}) format('opentype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "Montiac";
    src: url(${withPrefix('/fonts/Montiac-Regular.woff')}) format('woff'),
    url(${withPrefix('/fonts/Montiac-Regular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "PufflingCondensed";
    src: url(${withPrefix(
      '/fonts/PufflingV02-Condensed.woff2'
    )}) format('woff2'),
    url(${withPrefix('/fonts/PufflingV02-Condensed.woff')}) format('woff'),
    url(${withPrefix('/fonts/PufflingV02-Condensed.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Sawad";
    src: url(${withPrefix('/fonts/Sawad-Black.eot')});
    src: url(${withPrefix(
      'fonts/Sawad-Black.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix('/fonts/Sawad-Black.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Sawad-Black.woff')}) format('woff'),
    url(${withPrefix('/fonts/Sawad-Black.otf')}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "Sawad";
    src: url(${withPrefix('/fonts/Sawad-ExtraBlack.eot')});
    src: url(${withPrefix(
      'fonts/Sawad-ExtraBlack.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix('/fonts/Sawad-ExtraBlack.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Sawad-ExtraBlack.woff')}) format('woff'),
    url(${withPrefix('/fonts/Sawad-ExtraBlack.otf')}) format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "ZangeziSans";
    src: url(${withPrefix('/fonts/ZangeziSans09-Black.eot')});
    src: url(${withPrefix(
      'fonts/ZangeziSans09-Black.eot?#iefix'
    )}) format('embedded-opentype'),
    url(${withPrefix('/fonts/ZangeziSans09-Black.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/ZangeziSans09-Black.woff')}) format('woff'),
    url(${withPrefix('/fonts/ZangeziSans09-Black.otf')}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "CartaNueva";
    src: url(${withPrefix('/fonts/CartaNueva-Large.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "NotoSansNushu";
    src: url(${withPrefix(
      '/fonts/NotoSansNushu-Regular.ttf'
    )}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Rezak";
    src: url(${withPrefix('/fonts/RezakNew-Regular.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "MinSans";
    src: url(${withPrefix('/fonts/MinSans-Book_Trial.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "MinSans";
    src: url(${withPrefix('/fonts/MinSans-Bold_Trial.otf')}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Resolve";
    src: url(${withPrefix('/fonts/Resolve-Bold.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Resolve-Bold.woff')}) format('woff'),
    url(${withPrefix('/fonts/Resolve-Bold.otf')}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Resolve";
    src: url(${withPrefix('/fonts/Resolve-Book.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Resolve-Book.woff')}) format('woff'),
    url(${withPrefix('/fonts/Resolve-Book.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Resolve";
    src: url(${withPrefix('/fonts/Resolve-Italic.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Resolve-Italic.woff')}) format('woff'),
    url(${withPrefix('/fonts/Resolve-Italic.otf')}) format('opentype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Oniresi";
    src: url(${withPrefix('/fonts/OniresiPankwa-Regular.woff')}) format('woff'),
    url(${withPrefix('/fonts/OniresiPankwa-Regular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Tiffin";
    src: url(${withPrefix('/fonts/Tiffin-Regular.woff')}) format('woff'),
    url(${withPrefix('/fonts/Tiffin-Regular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "SilpanchoStencil";
    src: url(${withPrefix('/fonts/Silpancho-Stencil.woff')}) format('woff'),
    url(${withPrefix('/fonts/Silpancho-Stencil.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Thyrsa";
    src:    url(${withPrefix('/fonts/ThyrsaMMTPro-Bold.eot')});
    src:    url(${withPrefix(
      '/fonts/ThyrsaMMTPro-Bold.eot?#iefix'
    )}) format('embedded-opentype'),             
    url(${withPrefix('/fonts/ThyrsaMMTPro-Bold.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/ThyrsaMMTPro-Bold.woff')}) format('woff'),
    url(${withPrefix('/fonts/ThyrsaMMTPro-Bold.otf')}) format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Pulque";
    src: url(${withPrefix('/fonts/Pulque-Regular.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Pulque-Regular.woff')}) format('woff'),
    url(${withPrefix('/fonts/Pulque-Regular.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "StrecoStencilSuperfat";
    src: url(${withPrefix(
      '/fonts/DEMOStrecoStencil-Superfat.woff2'
    )}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "KachiBuwa";
    src: url(${withPrefix('/fonts/Kachi-Buwa.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/Kachi-Buwa.woff')}) format('woff'),
    url(${withPrefix('/fonts/Kachi-Buwa.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Gallarda";
    src: url(${withPrefix('/fonts/gallarda-Regular.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/gallarda-Regular.woff')}) format('woff'),
    url(${withPrefix('/fonts/gallarda-Regular.ttf')}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Monogame";
    src: url(${withPrefix('/fonts/Monogame-Display.woff')}) format('woff'),
    url(${withPrefix('/fonts/Monogame-Display.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Memorandum";
    src: url(${withPrefix('/fonts/Memorandum-Display.woff')}) format('woff'),
    url(${withPrefix('/fonts/Memorandum-Display.otf')}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "ArcadiaPawn";
     src:
     url(${withPrefix('/fonts/ArcadiaPawn.woff')}) format('woff');    
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "BeamLight";
    src: url(${withPrefix('/fonts/Beam-Light.otf')}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "DTStraitsSansBold";
     src:
     url(${withPrefix('/fonts/DTStraitsSans-Bold.woff')}) format('woff');    
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "MadinatiRegular";
     src:
     url(${withPrefix('/fonts/Madinati-Regular.woff')}) format('woff');    
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "MeenarExtraLight";
     src:
     url(${withPrefix('/fonts/Meenar-ExtraLight.woff')}) format('woff');    
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "MongaKC";
    src:
     url(${withPrefix('/fonts/Monga_KC.woff')}) format('woff');    
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "StralicHairline";
    src:
     url(${withPrefix('/fonts/Stralic-Hairline.woff')}) format('woff');    
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "WanwanBetaBold";
    src:
     url(${withPrefix('/fonts/Wanwan_beta-Bold.woff')}) format('woff');    
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "script6Italic";
    src: url(${withPrefix('/fonts/script6-Italic.woff2')}) format('woff2'),
    url(${withPrefix('/fonts/script6-Italic.woff')}) format('woff'),
    url(${withPrefix('/fonts/script6-Italic.ttf')}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  * {
    box-sizing: border-box;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 100%;
  }

  body {
    font-family: 'Malee Serif', serif;
    line-height: 1.3;
    font-size: 1rem;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    overflow-x: hidden;
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgBtZDRCcAgDESv7aLdpG5SR+kqnUQj+qEQQ07w4H5C3gsE2JRH+mIhBUytlKAHKYEGugQWaAo8oCpgwEFwSg/wGZhAXI2aLayCHoEJWgIXqAnibOmazD/Uj/7SGzuSAX5+VzTdhWI6AAAAAElFTkSuQmCC') 5 5, auto;
    cursor: -webkit-image-set(
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgBtZDRCcAgDESv7aLdpG5SR+kqnUQj+qEQQ07w4H5C3gsE2JRH+mIhBUytlKAHKYEGugQWaAo8oCpgwEFwSg/wGZhAXI2aLayCHoEJWgIXqAnibOmazD/Uj/7SGzuSAX5+VzTdhWI6AAAAAElFTkSuQmCC') 1x,
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACaSURBVHgB1dbRCYAwDATQUxdwJSczo7mBq7iBVvRDoZQmTU48uP4+KKUJ8KPMqWvqCFJOcL9LgZ8gBc6BoXAJDIFrQFdYA7rAFrAJbgFNsAeogj3BKjgCLMKRYBZmgC+4T0eHjyIgXy8DLr5gYYMRsOpXEjboATdNGmGDFth1exA2WAOHboTCBnOwCRygz4JrSJzYlLqBGPOVHl3Zdm69CKmtAAAAAElFTkSuQmCC') 2x
    ) 5 5, auto;
  }
  a, button, .cursor-pointer {
cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgBtZDRCcAgDESv7aLdpG5SR+kqnUQj+qEQQ07w4H5C3gsE2JRH+mIhBUytlKAHKYEGugQWaAo8oCpgwEFwSg/wGZhAXI2aLayCHoEJWgIXqAnibOmazD/Uj/7SGzuSAX5+VzTdhWI6AAAAAElFTkSuQmCC') 5 5, auto;
    cursor: -webkit-image-set(
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgBtZDRCcAgDESv7aLdpG5SR+kqnUQj+qEQQ07w4H5C3gsE2JRH+mIhBUytlKAHKYEGugQWaAo8oCpgwEFwSg/wGZhAXI2aLayCHoEJWgIXqAnibOmazD/Uj/7SGzuSAX5+VzTdhWI6AAAAAElFTkSuQmCC') 1x,
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAeCAYAAADQBxWhAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACaSURBVHgB1dbRCYAwDATQUxdwJSczo7mBq7iBVvRDoZQmTU48uP4+KKUJ8KPMqWvqCFJOcL9LgZ8gBc6BoXAJDIFrQFdYA7rAFrAJbgFNsAeogj3BKjgCLMKRYBZmgC+4T0eHjyIgXy8DLr5gYYMRsOpXEjboATdNGmGDFth1exA2WAOHboTCBnOwCRygz4JrSJzYlLqBGPOVHl3Zdm69CKmtAAAAAElFTkSuQmCC') 2x
    ) 5 5, auto;

  }
  // // squared off
  // a, button, .cursor-pointer {
  //   cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAYAAABi8KSDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAmSURBVHgB7coxDQAACMTABiP4V4UUQAIsP32TbgdQex/O4JGxBg+zNgoIAtC8gQAAAABJRU5ErkJggg==') 5 5, auto;
  //   cursor: -webkit-image-set(
  //     url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAYAAABi8KSDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAmSURBVHgB7coxDQAACMTABiP4V4UUQAIsP32TbgdQex/O4JGxBg+zNgoIAtC8gQAAAABJRU5ErkJggg==') 1x,
  //     url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAYAAABiS3YzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAqSURBVHgB7cwxDQBACMDA5o28f1VIAUTARJt0PYDoc/D/WEhUVFRU9BpagocUEmfmxs0AAAAASUVORK5CYII=') 2x
  //   ) 5 5, auto;
  // }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 1.25rem;
  }

  .alert p {
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }

  hr {
    border: none;
    border-bottom: 1px solid;
    margin: 2rem 0;
  }

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
  }

  .js-focus-visible .focus-visible {
    outline-color: #000;
    outline-offset: 2;
    outline-style: dotted;
    outline-width: 2px;
  }

  /* *:focus-visible {
    outline-color: #000;
    outline-offset: 2;
    outline-style: dotted;
    outline-width: 2px;
  } */
`;
